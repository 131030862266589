<template>

  <section id="dashboard" class="mt-4">

    <div class="container">
        <div class="row mt-5">

            <SideNav v-bind:selected="5"/>

            <div class="col-12  mt-sm-3 mt-md-0">
                <div class="row">
                    <div class="col-4">
                      <span class="heading">Personal Details</span>
                    </div>
                </div>
                <form id="payment_method" class="mt-2">

                    <input id="email" type="email" name="email" placeholder="E-mail address" readonly v-model="email" />

                    <div class="mb-2"></div>
                    <label class="mb-2 small"><option value="method">Delivery Address</option></label>

                    <div class="row">
                        <div class="col-6">
                        <input id="firstname" type="text" name="first_name" v-model="firstName" placeholder="First Name"/>
                        </div>
                        <div class="col-6">
                        <input id="lastname" type="text" name="last_name" v-model="lastName" placeholder="Last Name"/>
                        </div>
                    </div>

                    <div class="mb-2"></div>


                    <div class="row">
                        <div class="col-6">
                        <input id="house_no" type="text" name="house_no" v-model="secondLine" placeholder="House or flat number"/>
                        </div>
                        <div class="col-6">
                        <input id="firstline" type="text" name="firstline" v-model="firstLine" placeholder="First line of address"/>
                        </div>
                    </div>

                    <div class="mb-2"></div>

                        <div class="row">
                            <div class="col-6">
                            <input id="city" type="text" name="city" v-model="city" placeholder="City"/>
                            </div>
                            <div class="col-6">
                            <input id="postcode" type="text" name="postcode" v-model="postCode" placeholder="Post Code"/>
                            </div>
                        </div>



                  <div class="total mt-3">



                    <div class="row mt-3">
                      <div class="col">

                      </div>
                      <div class="col">
                        <button v-on:click="SaveChanges" type="button" class="btn btn-custom mt-3">Save</button> 
                      </div>
                    </div>



                  </div>
                </form>
            </div>
        </div>
    </div>
  </section>

  <Footer/>
</template>

<script>

import Api from '../../scripts/api'

import Footer from '../../components/global/Footer'
import SideNav from '../../components/admin/SideNavigator'

export default {
    components: {
        Footer,
        SideNav
    },
    props: {
      account: Object
    },
    data() {
      return {
        email: '',

        city: '',
        postCode: '',
        firstLine: '',
        secondLine: '',

        firstName: '',
        lastName: ''
      }
    },
    created() {
      this.email = this.account.account.email.email;
      
      this.firstName = this.account.account.firstName;
      this.lastName = this.account.account.lastName;
      this.city = this.account.account.address.city;
      this.postCode = this.account.account.address.postCode;
      this.firstLine = this.account.account.address.firstLine;
      this.secondLine = this.account.account.address.secondLine;
      this.county = this.account.account.address.county;
    },
    methods: {
      SaveChanges: async function() {
        var result = await Api.changeDetails(this.firstName, this.lastName, this.city, this.postCode, this.firstLine, this.secondLine);
        if(result.status != 200) return alert('Failed to changed details, try again later...');
        alert('Details updated successfully.');
      }
    }
}
</script>